@import "../../../../theme/css-variables/master-variables.css";

.plain-data-table {
    width: 100%;
    overflow-y: auto;
    font-size: 13px;

    & .data-header-cell {
        max-width: 102px;
        flex-grow: 1;
        height: 100%;
        line-height: 26px;
        padding-left: 5px;

        & .plain-data-table-sort-icon {
            padding-top: 5px;
        }
    }

    & .data-header-cell:last-child {
        max-width: none;
    }
    & .data-header-cell:first-child {
        padding-left: 0px;
    }

    & .data-table-row-restrict {
        padding-left: 34px;
    }

    & .plain-table-body {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 225px;
        margin-bottom: 5px;

        & .data-table-row-selected {
            & .z-n-icon {
                cursor: pointer;
            }
        }
    }

    & .data-table-cell {
        max-width: 96px;
        padding-left: 15px;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & .z-n-icon {
        padding-left: 0px;
        width: 30px;
        flex-grow: 0;

        &:first-child {
            padding-left: 4px;
        }

        & .pencil-icon {
            width: 18px;
            height: 18px;
        }
    }
}

.cell-stretch {
    & .data-table-row {
        & .data-table-cell {
            max-width: 80%;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .plain-data-table .plain-table-body {
        border-bottom: 5px solid var(--base-palette-4-$(val));
    }
}
