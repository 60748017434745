@import "../../../../../../../../theme/css-variables/master-variables.css";

.layer-module .surface-display-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%;

    &.hide {
        display: none;
    }

    & .surface-display-select-row {
        display: flex;
        flex-direction: row;

        & > div {
            flex-grow: 1;
            max-width: calc(50% - 11px);

            & .form-input {
                border-radius: 0;
                margin: 5px;
            }
        }

        &.right-aligned-row {
            padding-left: 22px;

            & > div {
                max-width: 50%;
            }
        }

        & .surface-display-layer-list .select-form-input-option span.letter-icon {
            height: 17px;
            min-width: 17px;
            line-height: 17px;
            font-size: 12px;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0 5px;
            text-align: center;
            box-shadow: none;
        }
    }

    & .surface-display-map {
        margin: 5px;
        flex-grow: 1;
    }

    & .floating-dgg {
        left: auto;
        max-width: 80%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        & .dgg-summary {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;

            & .spacer {
                flex-grow: 1;
            }

            & table {
                margin: 0 3px 0 4px;

                & td {
                    line-height: 20px;
                    padding: 0 3px;
                }

                & td:nth-child(1),
                & td:nth-child(2) {
                    text-align: right;
                }
                & td:nth-child(3) {
                    text-align: left;
                }
            }

            & .dgg-clear {
                padding: 2px 2px 0 4px;
                cursor: pointer;
                pointer-events: auto;
            }
        }

        & .dgg-label {
            line-height: 20px;
            padding: 0 7px;
            pointer-events: none;
            text-align: center;
        }
    }

    & .floating-dgg-4-column {
        grid-auto-rows: 10px !important;
        grid-template-rows: repeat(4, 1fr);
        grid-auto-flow: column;
        display: grid;
        width: min-content;

        & .dgg-label {
            content-visibility: hidden;
        }
    }

    & .floating-dgg-2-column {
        grid-auto-rows: 10px !important;
        grid-template-rows: repeat(9, 1fr);
        grid-auto-flow: column;
        display: grid;
        width: min-content;
    }

    & .floating-legend {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        max-width: calc(50% - 11px);

        & .legend-checkbox {
            margin: 0;
            font-size: 13px;
            height: 21px;
            width: inherit;

            & .checkbox-label {
                margin: 0;
            }
        }

        & .sample-sites-icon {
            border-radius: 50%;
            height: 12px;
            margin: 3px 0 0 7px;
            width: 12px;
        }

        & .legend-class-row {
            position: relative;
            display: flex;

            & .legend-label.legend-label-hover {
                margin-left: 20px;
            }

            &.sample-sites-section {
                cursor: pointer;
            }

            & .sample-sites-label {
                font-size: 13px;
                flex-grow: 1;
                height: 20px;
                line-height: 20px;
                overflow: hidden;
                padding: 0 7px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .esriMapTooltip {
            background-color: var(--black-white-toggle-$(val));
            color: var(--white-black-toggle-$(val));
        }

        & .surface-display-container {
            & .floating-dgg {
                background-color: var(--shadow-rgb3-$(val));
                color: var(--white-$(val));

                & .dgg-summary {
                    border: 1px solid rgba(0, 255, 255, 0.75);

                    & .dgg-clear {
                        background-color: rgba(0, 255, 255, 0.75);
                    }
                }
            }

            & .floating-legend {
                color: var(--white-$(val));

                & .sample-sites-section,
                & .legend-label,
                & .legend-filler {
                    background-color: var(--shadow-rgb3-$(val));
                }
            }
        }
        & .layer-module .select-form-input-option span.letter-icon {
            color: var(--black-white-toggle-$(val));
            border: 1px solid var(--black-white-toggle-$(val));
        }
    }
}
