.api-result {
    & .dialog-container {
        min-height: 75px;

        & .dialog-box-body {
            min-height: 50px;
            user-select: auto;

            & .detail-block:not(:last-child) {
                margin-bottom: 2em;
            }

            & .detail-item-list {
                display: block;
                list-style-type: disc;
                margin: 0.5em 0;
                padding-left: 20px;
            }

            & .detail-item:not(:last-child) {
                margin-bottom: 0.5em;
            }
        }

        & .dialog-box-title {
            padding: 0;
            margin: 0;
        }
    }
}
