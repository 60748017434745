.export-controller-custom-filename {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .zip-name {
        grid-column: span 2;

        & .text-form-input {
            width: calc(100% - 30px);
            margin-bottom: 0;
        }
    }

    & .file-list {
        grid-column: span 2;
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 0.5rem;
        align-items: center;
        width: calc(100% - 30px);

        & label {
            text-wrap: nowrap;
        }

        &.one-column {
            grid-template-columns: 1fr;
        }

        & .text-form-input {
            width: 100%;
            margin: 5px 10px;
        }

        & .field {
            margin: 5px 0;
            grid-column: span 2;
        }
    }
}
