@import "../../../theme/css-variables/master-variables.css";

.toolbar {
    height: 50px;
    user-select: none;
    display: flex;
    flex-direction: row;

    & .toolbar-loader:hover,
    & .toolbar-loader .loader-container,
    & .toolbar-loader .loader-container > div {
        cursor: default !important;
        background-color: transparent !important;
        position: relative;
    }

    & li {
        min-width: 70px;
        min-height: 50px;
        cursor: pointer;
        display: inline-block;

        & > div {
            width: 70px;
            height: 50px;
        }

        & .toolbar-item {
            position: relative;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: 23px 7px;

            & .toolbar-item-icon-container {
                position: relative;
                & .toolbar-item-icon {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 23px 7px;
                }

                & .toolbar-item-count {
                    position: absolute;
                    right: 0px;
                    margin-top: 4px;
                    margin-right: 2px;
                    color: #ffffff;
                    background-color: #e26816;
                    padding: 3px 4px 3px 4px;
                    font-size: 11px;
                    border-radius: 5px;
                }
            }

            & .toolbar-item-text {
                position: absolute;
                bottom: 5px;
                text-align: center;
                width: 50px;
                margin: 0 10px;
                font-size: 10px;
            }

            & .toolbar-item-arrow {
                position: absolute;
                bottom: 2px;
                right: 0;
                width: 12px;
                height: 12px;
            }

            &.disabled {
                cursor: default;
            }
        }

        & .toolbar-tool.selected {
            height: 47px;

            & .toolbar-item-text {
                bottom: 2px;
            }
        }

        & .toolbar-menu-content {
            position: relative;
            z-index: 200;

            &.rtl {
                position: absolute;
                left: auto;
                right: 0;

                & .toolbar {
                    position: relative;
                }
            }

            & > ul,
            & > div {
                border-radius: 0 0 5px 5px;
            }

            & > div {
                padding: 5px;
                font-size: 10px;
            }

            & > ul li:first-child {
                border-radius: 0 0 0 5px;
            }
            & > ul li:last-child {
                border-radius: 0 0 5px 0;
            }

            & .toolbar-close-bar {
                min-width: 10px;
                font-size: 10px;
                text-align: center;
                height: 51px;

                &:hover {
                    height: 50px;
                }
            }
        }

        & .toolbar-menu-content-wrapper {
            position: relative;

            & .toolbar-close-bar {
                position: absolute;
                top: 0;
                right: -10px;
                height: 100%;
                border-radius: 0 0 4px 0;

                &.rtl {
                    left: -10px;
                    right: auto;
                    border-radius: 0 0 0 4px;
                }

                &:hover {
                    height: 100%;
                }
            }
        }

        & .toolbar-menu.active {
            height: 47px;

            & .toolbar-item-text {
                bottom: 2px;
            }

            & .toolbar-item-arrow {
                bottom: -2px;
            }
        }

        & .toolbar-menu.disabled {
            cursor: default;
        }
    }

    & .toolbar {
        position: absolute;
    }

    & li > div.expanded .toolbar-item-arrow {
        display: none;
    }

    & li:first-child .toolbar-tool.selected {
        border-radius: 0 0 0 4px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .toolbar {
        color: var(--white-black-toggle-$(val));

        & li .toolbar-menu-content {
            & > ul,
            & > div {
                color: var(--white-black-toggle-$(val));
                background-color: var(--base-palette-1-$(val));
                border-bottom: 1px solid var(--highlight-$(val));
            }

            & .toolbar-menu-content-wrapper {
                border-right: 10px solid var(--highlight-$(val));
            }
        }

        & li .active {
            color: var(--highlight-$(val));
            background-color: var(--base-palette-1-$(val));
            border-bottom: 3px solid var(--highlight-$(val));
        }

        & li > div.expanded {
            color: var(--highlight-$(val));
            background-color: var(--base-palette-1-$(val));

            & .active {
                border-bottom: 3px solid var(--base-palette-1-$(val));
            }

            & .toolbar-close-bar {
                color: var(--base-palette-1-$(val));
                background-color: var(--highlight-$(val));

                &:hover {
                    color: var(--white-black-toggle-$(val));
                    background-color: var(--shadow-$(val));
                }
            }
        }

        & li .toolbar-tool.selected {
            border-bottom: 3px solid var(--highlight-$(val));
            background-color: var(--shadow-$(val));
            color: var(--highlight-$(val));
        }

        & li:hover {
            background-color: var(--shadow-$(val));
        }

        & .disabled {
            color: var(--base-palette-2-$(val)) !important;

            & .toolbar-item-text {
                color: var(--base-palette-2-$(val)) !important;
            }

            & svg {
                fill: var(--base-palette-2-$(val)) !important;
                &.disabled-stroke {
                    stroke: var(--base-palette-2-$(val)) !important;
                }
            }

            & .zone-explode {
                opacity: 0.6;
            }
        }

        & .toolbar-item-arrow {
            fill: var(--white-black-toggle-$(val));
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .toolbar {
        color: var(--white-$(val));

        & li {
            & .toolbar-menu-content {
                & > ul,
                & > div {
                    color: var(--white-$(val));
                    background-color: var(--base-palette-1-$(val));
                    border-bottom: 1px solid var(--highlight-$(val));
                }

                & .toolbar-menu-content-wrapper {
                    border-right: 10px solid var(--highlight-$(val));
                }
            }

            & .toolbar-tool,
            & .toolbar-button {
                & .toolbar-item-text {
                    color: var(--black-$(val));
                }
            }
        }

        & li .active {
            color: var(--highlight-$(val));
            background-color: var(--base-palette-1-$(val));
            border-bottom: 3px solid var(--highlight-$(val));
        }

        & li > div.expanded {
            color: var(--highlight-$(val));
            background-color: var(--base-palette-1-$(val));

            & .active {
                border-bottom: 3px solid var(--base-palette-1-$(val));
            }

            & .toolbar-close-bar {
                color: var(--base-palette-1-$(val));
                background-color: var(--highlight-$(val));

                &:hover {
                    color: var(--white-black-toggle-$(val));
                    background-color: var(--shadow-$(val));
                }
            }
        }

        & li .toolbar-tool.selected {
            border-bottom: 3px solid var(--highlight-$(val));
            background-color: var(--shadow-$(val));
            color: var(--highlight-$(val));
        }

        & li:hover {
            background-color: var(--shadow-$(val));
            color: var(--black-$(val));

            & .toolbar-svg-icon {
                fill: var(--black-$(val));
            }

            & .toolbar-item-arrow {
                fill: var(--black-$(val));
            }
        }

        & .disabled {
            color: var(--base-palette-3-$(val)) !important;

            & .toolbar-item-text {
                color: var(--base-palette-3-$(val)) !important;
            }

            & svg {
                fill: var(--base-palette-3-$(val)) !important;
                stroke: var(--base-palette-3-$(val)) !important;
            }

            & .zone-explode {
                opacity: 0.6;
            }
        }

        & .toolbar-item-arrow {
            fill: var(--white-$(val));
        }

        &.process-count-tools li {
            color: var(--black-$(val));
            background-color: var(--base-palette-6-$(val));
        }
    }
}
