@import "../../../theme/css-variables/master-variables.css";
@import "../form-input.css";

div.text-form-input {
    & div.input-label-container {
        font-size: 10px;
        line-height: 7px;
        min-height: 7px;
        text-indent: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 10%;
        max-width: 100%;

        & .red-star {
            font-size: 15px;
            line-height: 10px;
            padding-right: 5px;
        }

        &.label-visible {
            line-height: 12px;
        }
    }

    & .input-container {
        display: flex;
        flex-direction: row;

        & .required-star {
            font-size: 15px;
            line-height: 15px;
        }

        & input {
            flex-grow: 1;
            min-width: 20px;
            height: 22px;
            border: none;
            outline: none;
            text-indent: 5px;
            text-overflow: ellipsis;

            &.hint-visible {
                line-height: 17px;
            }
        }

        & .clear-btn-icon {
            margin: 4px 0;
            padding: 2px;
            border: none;
            cursor: pointer;

            & .default-svg-icon {
                width: 15px;
                height: 15px;
            }
        }

        &:not(.focus):not(:hover) {
            & .clear-btn-icon {
                visibility: hidden;
            }
        }
    }
}
& [data-tooltipinput]::before {
    /* needed */
    content: attr(data-tooltipinput);
    position: absolute;
    opacity: 0;

    /* customizable */
    white-space: pre-line;
}

& [data-tooltipinput]:hover::before {
    /* needed */
    opacity: 1;

    /* customizable */
    box-shadow: 1px 1px 3px #222222;
    border-radius: 2px;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
    margin-top: 26px;
    margin-left: 10px;
    padding: 4px;
    transition-delay: 0.75s;
    z-index: 10;
}

& [data-tooltipinput]:not([data-tooltipinput-persistent])::before {
    pointer-events: none;
}

@each $val in var(--theme-array) {
    .$(val) {
        & .form-input-error {
            /* FIXME: everything in this file should be nested under `.text-form-input` */
            border: 2px solid var(--error-$(val)) !important;
            border-radius: var(--borderRadiusSize);
        }

        & .text-form-input {
            & .input-label-container {
                color: var(--black-$(val));

                & .red-star {
                    color: var(--error-$(val));
                }
            }

            & .required-star {
                color: var(--error-$(val));
            }

            & .clear-btn-icon .default-svg-icon {
                fill: var(--black-$(val));
            }
        }
        & [data-tooltipinput]:hover::before {
            background: var(--base-palette-3-$(val));
            color: var(--black-$(val));
        }
    }
}
