@import "../../../../../../theme/css-variables/master-variables.css";

.layer-module .layer-accordion-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .layer-label {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
        margin-left: 5px;
    }

    & .layer-status {
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
    }

    & .layer-status-completed {
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 25px;
    }

    & .layer-item-loader {
        float: left;

        & .loader-container {
            position: relative;
        }

        & .loader-container > div {
            background-color: transparent;
        }

        & .ball-clip-rotate > div {
            height: 15px;
            width: 15px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module .layer-accordion-item {
        background-color: var(--base-palette-4-$(val));

        & .layer-status {
            color: var(--attention-$(val));
        }

        & .layer-status-completed {
            color: var(--highlight-$(val));
        }
    }

    .$(val) .letter-icon.has-visible {
        color: var(--base-palette-1-$(val));
        background-color: var(--highlight-$(val));
    }
}
