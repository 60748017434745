.field-boundary-overlap-option-description {
    margin: 12px 20px;
}

.field-boundary-overlap .dialog-box-body {
    max-width: 420px !important;
}

.field-boundary-overlap-description {
    padding-bottom: 15px;
}
