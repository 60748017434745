@import "../../../../../../theme/css-variables/master-variables.css";

.layer-module {
    & .feature-data-item {
        height: 54px;

        & .row {
            display: flex;
            flex-direction: row;
            height: 25px;
        }

        & .letter-icon {
            margin-top: 3px;
            margin-bottom: 3px;
        }

        & .feature-type {
            display: flex;
            align-items: center;
            margin-left: 6px;
        }

        & .feature-row {
            display: flex;
            flex-direction: row;
        }

        & .left-column {
            display: flex;
            align-items: center;
            margin-left: 40px;
        }

        & .radio-button {
            display: inline-block;
            width: auto;
            height: auto;
        }

        & .feature-name {
            display: flex;
            align-items: center;
            margin-left: 6px;
            flex-grow: 1;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module .feature-data-item {
        & .top-row {
            background-color: var(--base-palette-4-$(val));
        }

        & .feature-row {
            background-color: var(--shadow-$(val));

            & .context-menu .menu-dot {
                color: var(--shadow-$(val));
            }

            &:hover {
                & .context-menu .menu-dot {
                    color: var(--highlight-$(val));
                }
            }
        }
    }
}
