.setup-attribute-color-override {
    & .attribute-color-override-grid {
        & .data-table-header {
            height: 20px;
            padding-right: 16px;
        }

        & .data-header-cell {
            max-width: none;
            width: 25%;
            padding-left: 0px;
            line-height: normal !important;

            & .data-table-cell {
                text-transform: capitalize;
            }

            & .data-table-cell-relative {
                padding-left: 0px;
            }

            &.attribute {
                padding-left: 12px;
            }

            &.color {
                max-width: 5%;
            }
        }

        & .data-table-row {
            padding-right: 16px;
        }

        & .data-table-cell {
            max-width: none;

            &.attribute-type {
                padding-left: 0px;
                width: calc(25% - 19px);
            }

            &.attribute {
                padding-left: 19px;
                width: calc(25% - 19px);
            }

            &.color {
                padding-left: 8px;
                max-width: 16px;
            }
        }

        & .plain-table-body {
            margin-bottom: 0;
        }
    }

    & .color-picker-container {
        padding: 0 50px 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
    }

    & .add-link-container {
        font-size: 13px;
        margin-top: 6px;

        &.no-table {
            margin-top: 10px;
        }
    }
}
