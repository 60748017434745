@import "../../theme/css-variables/master-variables.css";

.form-input {
    height: 35px;
    border-radius: 4px 4px 4px 4px;
    padding: 3px 3px;
    margin: 10px;
    font-family: "open_sansregular";
    font-size: 13px;
    font-weight: 300;

    & input {
        font-family: "open_sansregular";
        margin-top: 1px;
    }

    & textarea {
        font-family: "open_sansregular";
    }

    & ::placeholder {
        font-family: "open_sansitalic";
        font-size: 13px;
        font-weight: 300;
        opacity: 1;
    }

    &.error {
        &:not(.no-error-text) {
            height: 45px;
        }

        & .input-label-container {
            height: 11px;
        }
    }

    & .disabled {
        &.required-star,
        & .expand-contract-arrow {
            opacity: 0.6;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .form-input {
        background-color: var(--base-palette-3-$(val));
        border: 2px solid var(--base-palette-3-$(val));
        color: var(--black-white-toggle-$(val));

        & input,
        & textarea {
            background-color: var(--base-palette-3-$(val));
            color: var(--black-white-toggle-$(val));
        }

        & input:-webkit-autofill {
            -webkit-text-fill-color: var(--base-palette-1-$(val)) !important;
            -webkit-box-shadow: 0 0 0px 1000px var(--base-palette-3-$(val)) inset;
        }

        &.focus {
            border: 2px solid var(--highlight-$(val));
        }

        & :enabled::placeholder {
            color: var(--black-$(val));
        }

        &.error {
            border: 2px solid var(--error-$(val));
        }

        & .error-text {
            color: var(--error-$(val));
        }

        & .disabled .input-label-container,
        & .input-label-container .disabled-text,
        & .disabled input,
        & input:disabled,
        & input:disabled::placeholder {
            color: var(--base-palette-2-$(val)) !important;
        }
    }
}
@each $val in var(--sky-array) {
    .$(val) .form-input {
        color: var(--black-$(val));

        & input,
        & textarea {
            color: var(--black-$(val));
        }

        & input:-webkit-autofill {
            -webkit-text-fill-color: var(--black-$(val)) !important;
            -webkit-box-shadow: 0 0 0px 1000px var(--black-$(val)) inset;
        }
    }
}
