.sampling-item-context-menu-container {
    & .sampling-item-context-menu.context-menu {
        white-space: nowrap;
        margin-top: 0;
        height: 25px;

        & .menu-dot {
            height: 6px;
        }
    }
}
