.onsite-param-input {
    & .input-container {
        width: 275px;
    }
}

.add-link-container {
    margin-top: 10px;
}

.auto-exchange-checkbox {
    margin: 10px 10px 0 10px;
}

.use-as-companylevel-checkbox {
    margin: 10px 10px 0 10px;
}
