@import "../../../../theme/css-variables/master-variables.css";

.section-container {
    display: flex;
    margin-bottom: 15px;
    max-width: 1240px;

    & .section-fence {
        border-width: 1px;
        margin-right: 13px;
    }

    & .no-bar {
        border: none;
        margin-left: 10px;
    }

    & .empty-form-section {
        margin-bottom: 15px;
    }
}

.form-section-bucket {
    max-width: 394px;
    padding: 0px;
    margin: 0px;

    & .bucket-header {
        width: 384px;
        height: 40px;
        padding-left: 10px;
        font-family: "open_sansregular";
        font-size: 15px;
        font-weight: 500;
        vertical-align: middle;
        line-height: 40px;
        margin-bottom: 10px;

        & .bucket-symbol {
            float: right;
        }
    }
}

.section-column {
    flex-direction: column;
}

.form-section {
    width: 394px;

    & .form-section-row-2 {
        margin-top: 65px;
    }

    & .form-section-row-3 {
        margin-top: 107px;
    }

    & .form-section-row-4 {
        margin-top: 137px;
    }
}

.form-section-header {
    height: 40px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: "open_sansregular";
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 40px;

    & .red-star {
        padding-right: 10px;
    }
}

.form-section-header-owner-telematics {
    height: 40px;
    padding-left: 10px;
    margin-bottom: 0px;
    font-family: "open_sansregular";
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 40px;
}

.grid-section {
    & .form-section-header {
        margin-bottom: 0px;
    }

    & .plain-data-table {
        & .data-table-header {
            & .data-table-cell.data-table-cell-relative.z-n-icon {
                cursor: default;
            }

            & .data-header-cell {
                cursor: pointer;

                & .data-table-cell.data-table-cell-relative {
                    flex-grow: 0;
                    padding-right: 5px;
                }
            }
        }

        & .plain-table-body {
            margin-bottom: 0px;
        }
    }

    & .add-link-container {
        padding-top: 7px;
    }
}
.form-section-children {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.form-section-child-stretch {
    width: 100%;

    &.mini-grid {
        display: flex;
        flex-direction: column;
    }

    & .add-link {
        font-size: 13px;
    }
}
.form-section-child-stretch.mini-grid {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .add-link {
        font-size: 13px;
    }
}

.form-sub-section-stretch {
    justify-content: space-between;
}

.form-sub-section {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;

    & .form-input {
        margin: 0;
        width: 50%;
        margin-right: 7px;
    }

    & .section-label {
        width: 50%;
        margin-right: 7px;
        margin: auto;
    }

    & .form-input:last-child {
        margin-right: 0px;
    }

    & .form-input-id.form-input:first-child {
        width: 160px;
        margin-bottom: 10px;
    }

    & .form-input:only-child {
        width: 183px;
    }

    & .form-input.density-input {
        width: 185px;
    }

    & .form-long-input.form-input:only-child {
        width: 100%;
    }

    & .select-form-input-container {
        margin: 0;
        width: 50%;
        margin-right: 7px;
        padding-right: 10px;
        & .select-form-input {
            width: 100%;
        }
    }

    & .form-input.textarea-form-input {
        width: 100%;
        height: 110px;
        margin: 0px;
    }

    & .select-form-input-container:last-child {
        margin-right: 0px;
    }

    & .select-form-input-container:only-child {
        width: 183px;
    }

    & .checkbox-div {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }
    & .active-checkbox {
        width: 100px;
        margin-left: 24px;
    }

    & .color-picker-container {
        margin-bottom: 10px;
    }

    & .date-form-input form-input text-form-input {
        margin: 0;
        width: 50%;
        margin-right: 7px;
    }
}

@each $val in var(--theme-array) {
    .$(val) .form-section-header {
        background-color: var(--base-palette-4-$(val));
        color: var(--white-$(val));

        & .red-star {
            color: var(--error-$(val));
        }
    }
    .$(val) .form-section-header-owner-telematics {
        background-color: var(--base-palette-4-$(val));
        color: var(--white-$(val));
    }
    .$(val) .form-section-bucket {
        & .bucket-header {
            background-color: var(--base-palette-4-$(val));
            color: var(--white-$(val));

            & .red-star {
                color: var(--error-$(val));
            }
        }
    }
    .$(val) .form-section-child-stretch {
        & .disabled-link {
            color: var(--base-palette-4-$(val));
        }
    }
}
