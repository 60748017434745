.water-sampling-info {
    display: flex;
    flex-direction: column;

    & .checkbox-div {
        margin-left: 3px;
    }

    & .temp-input {
        max-width: 85px;
    }

    & .textarea-form-input {
        width: 95%;
        height: 110px;
    }

    & .event-label-icon {
        height: 45px;
        width: 50px;
    }

    .event-sample-water-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: calc(100% - 275px);
        overflow: hidden;
        padding-top: 5px;

        & .sample-results-tbl-container {
            & .row {
                & > div:not(.attribute-name) {
                    width: 220px;
                }
            }
        }

        & .with-results {
            height: calc(100% - 5px);
            padding: 0 10px;
        }
    }
}

.water-sampling-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    width: 100%;
}

.water-sampling-radio-button {
    margin: auto;
    margin-left: 3px;
}

.water-sampling-input {
    width: auto;
    display: inline-block;
    margin: auto 0;
}

.water-sampling-type-input {
    width: 66%;
    display: inline-block;
    grid-column: 1 / 2 span;
}

.detailed-link {
    height: 100px;
    padding-top: 5px;
    padding-left: 218px;
}

.zone-icon {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-right: 12px;
}

.zone-info-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 0;
}

.zone-name {
    font-size: 14px;
    font-weight: 700;
}

.zone-size {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.no-zone-selected {
    padding: 15px 20px 10px 20px;
    color: #bfbfbf;
}
