@import "../../theme/css-variables/master-variables.css";

.login-err-animation {
    animation: shake 250ms ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

.mobile-root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: beige;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: white;
    font-family: Arial, sans-serif;

    display: flex;
    justify-content: center;

    & .box-title {
        font-size: 2em;
        font-weight: bold;
    }
    & .info {
        text-align: center;
        background-color: black;
        position: relative;
        width: 36em;
        height: 18em;
        padding: 2em;
        border-radius: 0.625em;
        margin-top: 7.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1em;
        margin-right: 1em;
    }
    & .allowedDownload {
        min-width: 40%;
        margin-left: auto;
        margin-right: auto;
        font-size: 140%;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    & .downloadLink {
        padding-top: 5px;
        color: #00e !important;
        text-decoration: none;
    }

    & .version {
        font-weight: bold;
        font-size: 130%;
        height: 2em;
    }

    & .message {
        font-size: 120%;
        height: 3.5em;
    }
}

.login-root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: "open_sansregular";
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    & > .login-page-container {
        display: flex;
        flex-grow: 1;

        & .login-box.hidden {
            display: none;
        }

        & .login-box {
            position: relative;
            background-image: none;
            min-width: 350px;
            padding: 20px;
            border-radius: 10px 10px 10px 10px;
            margin: 7.5% auto auto auto;

            & .login-logo-div {
                max-width: 293px;
                max-height: 77px;
                width: 293px;
                height: 77px;
                margin-left: auto;
                margin-right: auto;
            }

            & .validation-summary-errors {
                width: 100%;
                max-width: 800px;
                line-height: 20px;
                text-align: center;

                & > div {
                    text-align: left;
                    padding-left: 5px;

                    & ul {
                        list-style: disc;
                        padding-left: 40px;
                        text-align: left;
                    }
                }
            }
            & .login-eula-container {
                height: 400px;
                margin-top: -80px;

                & .display-label {
                    font-weight: 1000;
                    margin-bottom: 10px;
                }

                & .form-section {
                    width: 500px;
                    margin-bottom: 20px;
                    height: 320px;
                    overflow: auto;

                    & .form-section-children {
                        & .privacy-policy-body {
                            & p {
                                margin-top: 5pt;
                                & strong {
                                    font-weight: 1000;
                                }
                            }
                        }
                    }
                }
            }

            & .verification-container {
                height: 24em;
                margin-top: -5.4em;

                & .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }

                & .form-section {
                    width: 100%;
                    max-width: 500px;
                    height: 23em;
                    overflow: auto;

                    & .form-section-children {
                        & .enrollment-body {
                            text-align: center;
                            & .form-section-child-stretch {
                                flex-direction: row;
                                flex-flow: row wrap;
                            }
                            & .verification-instructions {
                                font-size: 1em;
                                margin-top: 5em;
                                margin-bottom: 3.5em;
                                & strong {
                                    font-weight: 1000;
                                }
                            }
                            & .enrollment-btn {
                                height: 45px;
                                max-width: 180px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 2em;
                            }
                            & .enrollment-resend-btn {
                                height: 45px;
                                max-width: 140px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 2em;
                            }

                            & .text-form-input {
                                width: 28%;
                                margin-left: 19%;
                            }
                            & .verify-btn {
                                height: 45px;
                                width: 28%;
                                margin-top: 0px;
                                margin-right: 2em;
                            }
                        }
                    }
                }
            }

            & .enrollment-container {
                height: 40em;
                margin-top: -5.4em;

                & .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }

                & .form-section {
                    max-width: 800px;
                    margin-top: 4em;
                    margin-bottom: 2em;
                    width: 100%;
                    height: 30em;
                    overflow: auto;
                    & .form-section-children {
                        & .enrollment-body {
                            font-family: Arial;
                            width: 98%;
                            & p {
                                margin-top: 5px;
                                margin-bottom: 5px;
                            }
                            & strong {
                                font-weight: bold;
                                margin-top: 5px;
                            }
                            & .privacy-section {
                                border: 0.5px solid;
                                padding: 5px;
                                & .privacy-checkbox {
                                    font-weight: bold;
                                    margin-top: 0.7em;
                                }
                                & .privacy-paragraph {
                                    margin-top: 0;
                                    padding-left: 2em;
                                }
                            }
                        }
                    }
                }
                & .accept-control {
                    display: flex;

                    & .accept-btn {
                        height: 32px;
                        font-size: 1.3em;
                        margin: auto;
                    }
                }
                & .private-confirm-modal {
                    width: 40em;
                    & .dialog-box-body {
                        max-width: 40em;
                        max-height: 10em;
                        overflow: hidden;
                    }
                }
            }

            & .enrollment-complete {
                height: 220px;
                margin-top: -5.4em;
                text-align: center;

                & .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }

                & .form-section {
                    max-width: 400px;
                    margin-top: 0.7em;
                    margin-bottom: 0.7em;
                    width: 100%;
                    height: 9em;
                    overflow: auto;
                    & .form-section-children {
                        & .enrollment-body {
                            text-align: center;
                            margin-top: 4em;
                            font-size: 1em;
                            & .form-section-child-stretch {
                                flex-direction: row;
                                flex-flow: row wrap;
                            }
                        }
                    }
                }
            }

            & .myagdata-container {
                margin-top: -5.4em;

                & .display-label {
                    font-weight: 1000;
                    font-size: 1.4em;
                    text-align: center;
                }

                & .form-section {
                    width: 100%;
                    max-width: 500px;
                }

                & .text-form-input {
                    width: 10em;
                }

                & .form-sub-section {
                    margin-top: 2em;
                    flex-direction: column;
                    align-items: center;
                    grid-gap: 1em;
                }

                & p {
                    text-align: center;
                }

                & p:nth-of-type(2) {
                    padding-top: 2em;
                }
            }

            & .inline-loader {
                position: relative;
            }

            & .login-input-container {
                width: 78%;
                margin-left: auto;
                margin-right: auto;

                & .display-label {
                    margin: 10.5px 10.5px 20px 10.5px;
                    line-height: 16px;
                }

                & .display-question {
                    margin: 0 10.5px;
                    line-height: 16px;
                }

                & .select-form-options {
                    margin-top: 0;
                }
            }

            & .login-options-div {
                margin-bottom: 23px;
                padding: 0 10px;
                display: flex;
                flex-direction: row;

                & .login-remember-me-container,
                & .login-forgot-password {
                    flex: 1;
                    margin: 1px;
                    overflow: hidden;
                    white-space: nowrap;
                }

                & .login-forgot-password {
                    line-height: 19px;
                    text-align: right;
                }
            }

            & .login-center {
                text-align: center;
            }

            & .login-btn {
                height: 32px;
                min-width: 100px;
                margin: 0 5px 50px 5px;
                border-radius: 4px;
                font-family: "open_sansregular";
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
}
@each $val in var(--theme-array) {
    .$(val) {
        & .login-box {
            background-color: var(--base-palette-1-$(val));
        }

        & .login-root {
            background-color: var(--base-palette-2-$(val));

            & > .login-page-container .login-box {
                & .validation-summary-errors {
                    background-color: var(--error-$(val));
                }

                & .login-btn {
                    background-color: var(--clickable-$(val));
                    border: 1px solid var(--clickable-$(val));
                    color: var(--white-$(val));
                }

                & .cancel-btn {
                    background-color: var(--base-palette-5-$(val));
                    border: 1px solid var(--base-palette-5-$(val));
                }
            }
        }
    }
}
