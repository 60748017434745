.field-resurface-modal {
    & .dialog-container {
        width: 30%;
        & .resurfaceMessage {
            padding: 0 0 1.5rem 0;
        }
        & .resurfaceEventList {
            margin: 0.5rem 1rem 0.75rem 0;
            & .resurfaceEventGroupHeader {
                margin-bottom: 0.5rem;
            }
            & .resurfaceEventGroupList {
                margin-left: 1rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}
