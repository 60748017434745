.export-controller-file-modal {
    & .row {
        display: flex;
        width: 100%;

        & .checkboxes {
            flex-grow: 1;
            padding-top: 6px;

            & .checkbox-div {
                margin: 2px 10px;
            }
        }

        & .select-form-input-container {
            width: 236px;
        }

        &.full-width .text-form-input {
            width: 100%;
        }

        &.two-column .text-form-input {
            width: 50%;
        }
    }
    & .bottom-row {
        width: 50%;
        margin-left: auto;
    }

    & .validation-message {
        margin: 8px 0;
    }
}
