.rec-general-form {
    & .checkbox-div {
        margin-left: 3px;
    }

    & .temp-input {
        max-width: 85px;
    }

    & .textarea-form-input {
        width: 95%;
        height: 110px;
    }

    & .person-list {
        width: 100%;
        & .person-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap;

            & .trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
            & .person-info {
                flex-grow: 1;
                justify-content: flex-start;
            }
        }
    }

    & .equipment-list {
        width: 100%;
        & .equipment-item {
            line-height: 25px;
            display: flex;
            flex-flow: row nowrap;

            & .equipment-trashcan-icon-container {
                width: 10%;
                padding-left: 10px;
            }
            & .equipment-info {
                flex-grow: 1;
                justify-content: flex-start;
                margin-left: 6px;
            }
            & .empty-container {
                padding-left: 6px;
            }
        }
    }
}
