.action-panel .rec-module {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.validation-feedback-modal {
    padding: 10px;
    padding-left: 10px;
    padding-right: 30px;
    width: 600px;
    height: auto;
}

.validation-feedback-modal .text-form-input {
    width: 50%;
}

.validation-feedback-modal .work-instructions.textarea-form-input {
    width: 100%;
    height: 4em;
}

.validation-feedback-modal .textarea-form-input {
    width: 100%;
    height: calc(12px * 1.5 * 7);
}
