@import "../../../theme/css-variables/master-variables.css";

.user-preference-main-container {
    & .theme-selector {
        margin-bottom: 10px;

        & .form-sub-section {
            min-height: 80px;
        }

        & .theme-control-cont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 145px;
            margin-left: auto;
            margin-right: auto;
            justify-content: flex-start;

            & .radio-div {
                width: 50px;
                height: 65px;
                display: flex;
                flex-direction: column;
                margin: 8px 10px;

                & .radio-label {
                    min-height: 35px;
                    min-width: 35px;
                }

                & .radio-button-container {
                    margin: 8px 35%;
                }
            }
        }
    }

    & .notification-settings {
        display: flex;
        flex-grow: 2;
        flex-direction: row;

        & .options-header {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 14px;
            margin-left: 5px;
        }
        & .notification-section {
            width: 190px;
        }
    }

    & .receive-notification-row {
        display: flex;
        margin: 3px 5px;
    }

    & .onsite-notifications {
        display: flex;
        margin: 3px 5px;
    }

    & .onsite-autoimport-container {
        display: flex;
        align-items: center;
        margin: 3px 5px;
        padding-bottom: 10px;
        height: 45px;
        & .multi-select-container {
            min-width: 242px;
            max-width: 242px;
            margin-left: 10px;
            & .form-input {
                padding-bottom: 0px;
                position: relative;
                top: -9px;
                left: -9px;
                width: 230px;
            }

            & .dropdown-menu {
                position: relative;
                top: -14px;
            }
        }
    }

    & .season-filter-section {
        margin-bottom: 6px;
        & .form-section-header {
            margin-bottom: 0;
        }
        &.no-table .form-section-header {
            height: 30px;
            padding-bottom: 5px;
        }
        & .setup-season-filter {
            margin-left: 5px;
        }
    }

    & .attribute-color-override-section {
        margin-bottom: 6px;
        & .form-section-header {
            margin-bottom: 0;
        }
        &.no-table .form-section-header {
            height: 30px;
            padding-bottom: 5px;
        }
        & .setup-attribute-color-override {
            margin-left: 5px;
        }
    }

    & .menu-open {
        & .form-input {
            width: 356px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .theme-selector .radio-div .radio-label {
        border: 2px solid var(--base-palette-5-$(val));
    }

    .$(val).radio-label {
        background-color: var(--base-palette-1-$(val));
    }
}
@each $val in var(--sky-array) {
    .$(val) .theme-selector .radio-div .radio-label {
        border: 2px solid var(--base-palette-5-$(val));
    }

    .$(val).radio-label {
        background-color: var(--base-palette-1-$(val));
    }
}
