.field-boundary-transfer {
    & .modal-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & .dialog-window {
        position: absolute !important;
        & .dialog-box-children {
            & .field-boundary-transfer-action-description {
                font-style: italic;
                padding-bottom: 15px;
                text-align: center;
                width: 260px;
            }
            & .field-boundary-transfer-radio-group {
                align-items: center;
                display: flex;
                flex-direction: row;
                gap: 25px;
                justify-content: center;
                & .radio-div {
                    width: unset;
                }
            }
        }
    }
}
