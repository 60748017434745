.layer-module .surface-display-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%;

    &.hide {
        display: none;
    }

    & .loader {
        position: relative;
        height: 100%;
    }

    & .event-surface-stats-table {
        &.hide {
            display: none;
        }

        & .data-table-header {
            height: 28px;
            margin-top: 5px;
        }

        & .data-header-cell {
            max-width: none;
            padding-left: 15px;
            width: 10%;

            & .data-table-cell {
                padding-left: 0px;
                text-transform: capitalize;
            }
        }

        & .data-table-row {
            width: calc(50vw - 48px);
        }

        & .data-table-cell {
            padding-left: 15px;
            max-width: none;
            width: 10%;
        }

        & .surface {
            width: 20%;
        }

        & .layer {
            width: 35%;
        }

        & > div.plain-table-body {
            max-height: none;
            position: absolute;
            top: 95px;
            bottom: 0px;
            width: 50%;
            overflow-y: auto;
        }
    }
}
