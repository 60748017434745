.layer-context-menu {
    height: 25px;

    & .menu-dot {
        height: 6px;
    }
}

.action-panel {
    & .layer-accordion-item {
        & .context-menu {
            margin-top: 0;
        }
    }
}
