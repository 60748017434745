@import "./../theme/css-variables/master-variables.css";
.with-report-container {
    display: flex;
    flex-grow: 1;
    max-height: calc(100% - 1px);

    & p.error-message {
        white-space: pre-wrap;
    }
}
.reports-container {
    flex-grow: 1;
    background-color: transparent;
    padding-left: 15px;
    display: flex;
    flex-direction: column;

    & .report-grid-col {
        width: 38%;
        max-width: 200px;
    }

    & .email-container.form-input.text-form-input {
        width: 50%;
    }

    & .content-table-container.data-table-cont.reports-table-cont {
        flex-basis: 180px;
    }

    & .options-header {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
    }

    & .report-options-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    & .report-table-header {
        padding-top: 28px;
        padding-bottom: 14px;
    }

    & .reports-table-cont {
        min-width: 99%;
        width: 99%;
        margin: 0;
        margin-right: 0px;
        flex-basis: 180px;

        & .data-table-rows {
            &:empty {
                height: 20px;
            }
        }
    }

    & .category-section {
        margin-left: 15px;
    }

    & .report-grid-cont {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & .season-column {
            max-width: 76px;
        }
    }

    & .report-options-accordion-item {
        & .expand-collapse-icon {
            height: 14px;
            width: 13px;
        }

        & .collapse-button-rotate-90 {
            transform: rotate(-90deg);
        }

        & .collapse-button-rotate-45 {
            transform: rotate(-45deg);
        }
    }

    & .reports-options-container {
        display: flex;
        max-height: 50%;

        & .report-options-inner-container {
            padding: 10px;
            flex-direction: columns;
            flex: 1;
            overflow-y: auto;

            & .report-options {
                padding: 0;
            }

            & .date-form-input-container:last-child {
                margin-right: 0;
            }

            & .report-options-accordion-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding-bottom: 10px;

                & .report-options-content {
                    min-height: unset;
                }

                & .bucket {
                    width: 384px;
                    margin: 2.5px 0;
                    padding-left: 0;

                    & .bucket-header {
                        background-color: #404040 !important;
                        color: white;
                        padding: 0 10px;
                        min-height: 40px;
                        min-height: 40px;
                        line-height: 40px;
                        font-size: 13px;

                        & .bucket-symbol {
                            float: right;
                        }
                    }

                    & .report-options {
                        padding: 5px;
                        padding-bottom: 0;

                        & .form-sub-section:last-child {
                            margin-bottom: 0;
                        }

                        & .checkbox-div {
                            margin-top: 12px;
                            margin-bottom: 0px;
                            width: 48%;
                        }
                    }
                }

                & .report-options-accordion-item-header {
                    cursor: pointer;
                    padding: 5px;
                    padding-left: 10px;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #000000;
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .create-button {
                margin-left: 0px;
                height: 37px;
            }
        }

        & .report-types {
            flex: 1;
            padding: 15px;
            overflow-y: auto;

            & .checkbox-div {
                margin-bottom: 5px;
            }
        }

        & .report-options {
            padding: 15px;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;

            & .date-form-input-container {
                margin-right: 8px;
                min-width: 49%;

                & .date-form-input.form-input.text-form-input {
                    margin-right: 10px;
                }

                & .rdtPicker {
                    position: fixed;
                }
            }

            & .report-options-content {
                min-height: 180px;

                & .select-form-input-container {
                    width: auto;
                }
            }

            & .crop-map-variety-crop-label {
                padding-left: 6px;
            }

            & .form-sub-section {
                &.two-report-options {
                    flex-wrap: wrap;
                    column-gap: 11px;
                    row-gap: 10px;

                    & .select-form-input-container {
                        margin-right: 0px;
                        padding-right: 7px;
                    }

                    & .checkbox-div {
                        margin-top: 12px;
                        margin-bottom: 0px !important;
                        width: 48%;
                    }

                    & .page-selection {
                        padding-top: 0px;
                        margin-right: 7px;
                        width: calc(50% - 15px);
                    }

                    & .nonField-features-checkbox {
                        width: 46%;
                    }
                }

                & .categories-link {
                    font-size: 13px;
                    line-height: 44px;
                    width: 50%;

                    &.report-options-modal-link {
                        line-height: 25px;
                        margin-top: 10px;
                        margin-bottom: auto;
                        padding-bottom: 0px;
                    }
                }

                & .yield-basemap-select {
                    width: 183px !important;
                }

                & .select-form-input-container + .categories-link {
                    margin-left: 2px;
                }

                &.create-button button {
                    margin-left: 0px;
                    height: 37px;
                }

                & .select-form-input-container.display-options-list {
                    width: 183px;
                }

                &.field-symbology-options {
                    width: 100%;
                    height: 35px !important;
                    line-height: 25px;
                    margin-bottom: 0 !important;

                    & > a {
                        margin: 10px 0 auto 4px !important;
                        width: 197px !important;
                        font-size: 13px;
                    }
                    & .checkbox-div {
                        margin-top: auto !important;
                        margin-bottom: 0 !important;
                    }
                }

                &.two-wide {
                    display: flex;
                    flex-flow: row wrap;

                    & > div,
                    & > a {
                        flex: 0 1 auto;
                        width: 46.5%;
                        margin-bottom: 10px;

                        &.form-section-child-stretch {
                            width: 100%;
                        }

                        &:nth-child(even) {
                            padding-right: 3px;
                        }
                    }
                }

                &.two-wide.force-wrap {
                    & > div:nth-child(even) {
                        margin-right: 0;
                    }
                }

                & .event-id-checkbox {
                    margin-right: 7px;
                }
                & .crop-yield-link-empty {
                    margin-right: 7px;
                }

                & .legend-type-radio-group {
                    width: 50%;
                    & .legend-type-radio-group-header {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                }

                & .tree-view-parent {
                    & .checkbox-div {
                        margin-top: 0px !important;
                        margin-bottom: 0px !important;
                    }
                }
            }

            & .farm-map-label-color-options {
                margin-left: 4px;
                width: calc(50% - 15px);
            }

            & .crop-yield-dialog {
                width: 415px;
            }
        }
    }

    & .reports-table-cont .data-table-checkbox {
        width: 5%;
        max-width: 5%;
    }

    & .report-viewer {
        flex: 1;
        height: 100%;
        display: flex;

        & iframe {
            width: 100%;
            height: 100%;
        }

        & .report-viewer-close {
            cursor: pointer;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .reports-table-cont {
            & .data-table-rows {
                &:empty {
                    background-color: var(--base-palette-4-$(val));
                }
            }
        }

        & .report-progress-text {
            color: var(--attention-$(val));
        }

        & .report-error-text {
            color: var(--error-$(val)) !important;
        }

        & .report-types {
            border-right: 1px solid var(--white-$(val));
        }

        & .reports-options-container {
            border-bottom: 1px solid var(--white-$(val));
        }
    }
}
