@import "../../../../../theme/css-variables/master-variables.css";

.analysis-info-bucket-header {
    padding-bottom: 5px;
}

.analysis-form-input {
    max-width: 180px;
    min-width: 164px;
}

.crop-benchmark-input {
    max-width: 130px;
    min-width: 110px;
}

.analysis-input {
    margin-left: 7px;
    height: 40px;
    & .checkbox-label {
        height: 40px;
    }
}

.analysis-error-message {
    padding: 10px;
}

.analysis-yield-label-cont {
    display: flex;
    flex-direction: column;
}

.analysis-yield-label {
    color: var(--base-palette-3-default);
}

@each $val in var(--theme-array) {
    .$(val) {
        & .analysis-error-message {
            background-color: var(--error-$(val));
        }
    }
}

.event-count {
    padding-left: 15px;
    padding-bottom: 7px;

    & .event-label {
        display: inline-block;
        margin-right: 3px;
    }

    & .event-list {
        display: inline-block;
        font-size: 15px;
        font-style: italic;
    }
}

.seasons {
    padding-left: 15px;
    padding-bottom: 15px;

    & .seasons-label {
        display: inline-block;
        margin-right: 3px;
    }

    & .seasons-list {
        display: inline-block;
        font-size: 15px;
        font-style: italic;
    }
}

.crops {
    padding-left: 15px;
}

.crop-information {
    line-height: 55px;
    height: 55px;
    min-width: 85px;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;

    & .crop-title {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
