@import "../../../../../../theme/css-variables/master-variables.css";

.layer-module {
    & .layer-accordion-item-sampling {
        cursor: default;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .row {
            display: flex;
            flex-direction: row;
            height: 25px;
        }

        & .sampling-row {
            display: flex;
            flex-direction: row;
            margin-left: 80px;
        }

        & .left-column {
            display: flex;
            align-items: center;
            margin-left: 40px;
        }

        & .layer-label {
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 75px;
        }

        & .feature-name {
            display: flex;
            align-items: center;
            margin-left: 6px;
            flex-grow: 1;
        }

        & .layer-status-completed {
            font-size: 12px;
            padding-right: 5px;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 5px;
            min-width: 55px;
        }

        & .layer-status-event-id {
            font-size: 12px;
            padding-right: 5px;
            overflow: hidden;
            white-space: nowrap;
            line-height: 25px;
            margin-left: 5px;
            min-width: 35px;
        }
    }
}

@each $val in var(--theme-array) {
    .$(val) .layer-module .layer-accordion-item-sampling {
        background-color: var(--shadow-$(val));

        & .context-menu .menu-dot {
            color: var(--shadow-$(val));
        }

        &:hover {
            & .context-menu .menu-dot {
                color: var(--highlight-$(val));
            }
        }

        & .layer-status-completed {
            color: var(--highlight-$(val));
        }
    }
}
