.layer-module .split-screen-compare-dialog {
    height: 95%;
    max-width: 97%;
    width: 97%;

    & .dialog-box-title {
        & .selected.default-svg-icon {
            fill: #8fa61a;
        }

        & .disabled.default-svg-icon {
            fill: #777777 !important;
            cursor: default;
        }
    }

    & .dialog-box-body {
        display: flex;
        margin: 0;
        padding: 0 2px;

        & > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        & .split-screen-row {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            position: relative;

            & .split-button-top,
            & .split-button-bottom {
                cursor: pointer;
                position: absolute;
                left: calc(50% - 23px);

                & .default-svg-icon {
                    width: 46px;
                    height: 46px;
                }
            }
            & .split-button-top {
                top: 5px;
            }
            & .split-button-bottom {
                top: auto;
                bottom: 3px;
            }

            &.split-4 {
                max-height: 50%;
            }

            &.split-hide {
                display: none;
            }
        }
    }
    & .zone-split-icon {
        width: 32px;
    }
}
