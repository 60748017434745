@import "../../../theme/css-variables/master-variables.css";
@import "../form-input.css";

.select-form-input-container {
    position: relative;
    min-width: 20px;

    & .select-form-input {
        & .expand-contract-arrow {
            margin: 4px 0;
            padding: 2px;
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            border: none;
            cursor: pointer;
            min-width: 11px;

            & .default-svg-icon {
                width: 15px;
                height: 15px;
            }
        }

        & .input-container input {
            cursor: pointer;
            min-width: 20px;
        }
    }

    & .select-form-input-options {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 10001;

        &:focus {
            outline: none;
        }

        & .select-form-input-option {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            line-height: 18px;
            box-sizing: border-box;
            height: 100%;
            display: block;
            padding-top: 3px;

            &.header {
                font-weight: bold;
                cursor: default;
            }

            & span {
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden;

                &.default-option {
                    margin-left: 10px;
                    position: fixed;
                }
                &.pre-match {
                    margin-left: 10px;
                }
                &.match {
                    text-decoration: underline;
                }
            }
            & .first-line,
            & .second-line {
                & span {
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

    &:not(.open-upward) {
        & .select-form-input-expanded.form-input.focus {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
            padding-bottom: 5px;
        }
        & .select-form-input-options {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
        }
    }

    &.open-upward {
        & .select-form-input-expanded.form-input.focus {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
            padding-top: 5px;
        }
        & .select-form-input-options {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }
    }
    & [data-tooltipselectoption]::before {
        /* needed */
        content: attr(data-tooltipselectoption);
        position: absolute;
        opacity: 0;

        /* customizable */
        white-space: pre-line;
    }

    & [data-tooltipselectoption]:hover::before {
        /* needed */
        opacity: 1;

        /* customizable */
        box-shadow: 1px 1px 3px #222222;
        border-radius: 2px;
        font-size: 12px;
        line-height: 15px;
        margin: auto;
        margin-top: 26px;
        padding: 4px;
        text-align: center;
        transition-delay: 0.75s;
        z-index: 10000;
    }

    & [data-tooltipselectoption]:not([data-tooltipselectoption-persistent])::before {
        pointer-events: none;
    }
}

@each $val in var(--theme-array) {
    .$(val) {
        & .select-form-input-container {
            & .expand-contract-arrow .default-svg-icon {
                fill: var(--black-$(val));
            }

            & .select-form-input-options {
                border: 2px solid var(--highlight-$(val));

                & .select-form-input-option {
                    color: var(--black-$(val));

                    &.selected {
                        background-color: var(--attention-$(val));
                    }
                    &:not(.header):hover,
                    &.filter-match {
                        background-color: var(--clickable-$(val));
                    }
                    &.default {
                        cursor: default;
                        background-color: initial;
                    }
                    & span.default-option {
                        color: var(--black-$(val));
                    }
                }
            }

            &:not(.open-upward) {
                & .select-form-input-options {
                    border-top: none;
                    box-shadow: 0px 4px 8px -1px var(--shadow-rgb5-$(val));

                    & .select-form-input-option {
                        border-top: var(--base-palette-4-$(val)) 1px solid;
                    }
                }
            }

            &.open-upward {
                & .select-form-input-options {
                    border-bottom: none;
                    box-shadow: 0px -4px 8px -1px var(--shadow-rgb5-$(val));

                    & .select-form-input-option {
                        border-bottom: var(--base-palette-4-$(val)) 1px solid;
                    }
                }
            }

            & [data-tooltipselectoption]:hover::before {
                background: var(--base-palette-3-$(val));
                color: var(--black-$(val));
            }
        }
    }
}

@each $val in var(--sky-array) {
    .$(val) .select-form-input-container .select-form-input-options .select-form-input-option {
        &:not(.header):hover,
        &.filter-match {
            background-color: var(--clickable-$(val));
            color: var(--white-$(val));
        }

        &.selected {
            color: var(--white-$(val));
        }
    }
}
