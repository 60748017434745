.action-panel .import-module .import-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .module-loader {
        width: 446px;
        height: 100%;
    }

    & .module-filter-row {
        justify-content: unset;
    }

    & .module-filters-inputs {
        max-width: 301px;
    }
}
